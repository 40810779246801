<template>
  <div>
    <div class="flix-form-group">
      <editBtn :onClick="function() { open = !open; checkAppointment();  }">
        <template v-slot:text>
          {{ $t('message.check', {name: $tc('message.appointments', serial.length)}) }}
        </template>
      </editBtn>
    </div>
    <transition name="flixFadeIn">
      <div :key="open">
        <div v-if="open && !ready" class="flix-form-group">
          <flixLoader />
        </div>
        <div v-if="open && error.length" >
          <div class="flix-alert flix-alert-warning flix-form-group" v-for="(e, k) in error" :key="k">
            <div>
              <b v-if="e.maxApplicants">100% {{ $t('message.applicants') }} ({{ e.booked }}/{{ e.maxApplicants }})<br /></b>
              <b v-else>{{ $t('message.datesDeactivatedForBookings') }}:<br>{{ $t('constants.weekdays')[$getWeekday($createDate(e.date.begin))] }} </b>
              {{ $beautifyDate(e.date) }}
            </div>
          </div>
        </div>
        <div v-else-if="open">
          <div class="flix-alert flix-alert-success flix-form-group">{{ $t('message.bookingsAllowed') }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    serial: [Array, Boolean],
    date: Object,
    id: [String, Number]
  },
  data () {
    return {
      open: false,
      variables: this.$getUserVariables(),
      assistent: {},
      error: [],
      ready: false
    }
  },
  methods: {
    getAssistent (callback) {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.variables.user.md5_id,
          ID: this.id
        },
        callback: function (ret) { this.assistent = ret.data[1]; callback() }.bind(this)
      })
    },
    checkDate (date) {
      this.$flix_post({
        url: 'booking/check_date',
        data: {
          user: this.variables.user.md5_id,
          begin: date.begin,
          end: date.end,
          ID: this.id
        },
        callback: function (ret) {
          if (!ret.data[0]) {
            return false
          }
          if (ret.data[1][3] >= 100) {
            this.error.push({ date: date, booked: (ret.data[1][1] * 1), maxApplicants: (ret.data[1][2] * 1) })
          }
        }.bind(this)
      })
      /*
      var b = this.$createDate(JSON.parse(JSON.stringify(date)).begin)
      var e = this.$createDate(JSON.parse(JSON.stringify(date)).end)
      var diff = (((((e.getTime() * 1) - (b.getTime() * 1)) / 60) / 1000))
      var overbooked = false
      for (var i = 0; i <= diff; i += 15) {
        if (overbooked) {
          break
        }
        var t = new Date(b.getTime())
        t.setMinutes(t.getMinutes() + i)
        if (t.getTime() > e.getTime()) {
          break
        }
        var maxApplicants = this.getMaxApplicants(t)
        var booked = this.countBooked(t, b, e)
        if (booked >= maxApplicants) {
          this.error.push({ date: date, booked: booked, maxApplicants: maxApplicants })
          overbooked = true
        }
      }
      */
    },
    getMaxApplicants (date) {
      var weekday = date.getDay()
      if (weekday === 7) {
        weekday = 0
      }
      weekday += 1
      if (typeof this.assistent.max_applicants[weekday] === 'undefined') {
        return 0
      }
      return this.assistent.max_applicants[weekday]
    },
    countBooked (date, begin, end) {
      var bookings = JSON.parse(JSON.stringify(this.$store.getters.bookings[this.id]))
      var booked = 0
      bookings.forEach(function (booking) {
        var b = this.$createDate(JSON.parse(JSON.stringify(booking)).begin)
        var e = this.$createDate(JSON.parse(JSON.stringify(booking)).end)
        if (begin.getTime() <= b.getTime() && date.getTime() <= e.getTime() && end.getTime() >= e.getTime()) {
          booked += 1
        }
      }.bind(this))
      return booked
    },
    checkAppointment () {
      this.$store.dispatch('getBookings', function () {
        this.getAssistent(function () {
          this.error = []
          this.ready = false
          if (this.serial) {
            JSON.parse(JSON.stringify(this.serial)).forEach(function (s) {
              s.begin = s.begin.Y + '-' + s.begin.m + '-' + s.begin.d + ' ' + s.begin.H + ':' + s.begin.i
              s.end = s.end.Y + '-' + s.end.m + '-' + s.end.d + ' ' + s.end.H + ':' + s.end.i
              this.checkDate(s)
            }.bind(this))
            this.ready = true
          } else {
            this.checkDate(this.date)
            this.ready = true
          }
        }.bind(this))
      }.bind(this))
    }
  },
  mounted () {
    this.open = false
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
